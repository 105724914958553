<template>
  <div class="steps-container">
    <Steps :current="step">
      <Step title="提交订单"></Step>
      <Step title="订单确认"></Step>
      <Step title="完成支付"></Step>
    </Steps>
  </div>
</template>

<script>
  export default {
    name: "orderProcess",
    props:{
      step: [Number]
    },
  }
</script>
