<template>
  <div class="create-order-container">
    <div class="main-section">
      <order-header :step="0"></order-header>

      <div class="purchase">
        <Form ref="purchaseForm" :model="purchaseForm" class="purchase-form">
          <div class="flex">
            <div class="purchase-label">项目名称:</div>
            <div class="project-name">{{ purchaseForm.project_name }}</div>
          </div>
          <div class="flex">
            <div class="purchase-label">所属公司:</div>
            <div class="corp-name">{{ purchaseForm.corp_name }}</div>
          </div>
          <div class="flex">
            <div class="purchase-label">班组名称:</div>
            <div class="team-name">{{ purchaseForm.team_name }}</div>
          </div>
          <div class="flex">
            <div class="purchase-label">购买成员数量:</div>
            <Input
              type="text"
              style="width:90px"
              v-model="purchaseForm.goods_quantity"
              @on-change="setMembers"
            ></Input
            >&nbsp; <span class="unit">人</span>
          </div>

          <div class="flex">
            <div class="purchase-label">购买年限:</div>
            <Select
              v-model="purchaseForm.purchase_year"
              style="width:90px"
              @on-change="setYears"
            >
              <Option
                v-for="item in yearList"
                :value="item.value"
                :key="item.value"
                >{{ item.label }}</Option
              >
            </Select>
            &nbsp;<span class="unit">年</span>
          </div>

          <div class="flex">
            <div class="purchase-label">单价:</div>
            <div class="right-box">78元/人</div>
          </div>
        </Form>
        <div class="purchase-btm">
          <label
            >总计：<span>¥{{ purchaseForm.order_amount }}</span></label
          >
          <div class="count-formula">
            <span class="formula-words"
              >计算公式:&nbsp; {{ purchaseForm.goods_price }} /人/年*
              {{ purchaseForm.goods_quantity }} 人*
              {{ purchaseForm.purchase_year }} 年=
              {{ purchaseForm.order_amount }}元</span
            >
          </div>
        </div>

        <div class="btn-area">
          <Button
            class="login_buttonBg order-button"
            type="primary"
            @click="billNext"
          >
            提交订单
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import orderHeader from "@/components/orderProcess";

export default {
  components: {
    orderHeader
  },
  data() {
    return {
      // 步骤条状态
      current: 0,
      userInfo: {},
      // 创建订单对象
      purchaseForm: {
        order_amount: 150.0,
        creator_id: "",
        creator_company_id: "",
        creator_name: "",
        creator_company_name: "",
        corp_name: "",
        team_id: "",
        project_id: "",
        team_name: "",
        project_name: "",
        goods_id: "",
        goods_name: "",
        goods_quantity: 10,
        goods_price: 150.0,
        purchase_year: 1,
        orderItems: []
      },
      goodsList: [],
      // 定时器名称
      timer: null,
      memberList: [
        {
          label: 10,
          value: 10
        },
        {
          label: 20,
          value: 20
        },
        {
          label: 30,
          value: 30
        },
        {
          label: 40,
          value: 40
        },
        {
          label: 50,
          value: 50
        },
        {
          label: 60,
          value: 60
        },
        {
          label: 70,
          value: 70
        },
        {
          label: 80,
          value: 80
        },
        {
          label: 90,
          value: 90
        },
        {
          label: 100,
          value: 100
        }
      ],
      yearList: [
        {
          label: 1,
          value: 1
        },
        {
          label: 2,
          value: 2
        },
        {
          label: 3,
          value: 3
        },
        {
          label: 4,
          value: 4
        },
        {
          label: 5,
          value: 5
        }
      ]
    };
  },
  async created() {
    this.getUserInfo();
  },
  activated() {
    // 进入页面时初始化值
    this.purchaseForm.goods_quantity = 10
    this.purchaseForm.purchase_year = 1
  },
  methods: {
    async getUserInfo() {
      const userInfo = this.common.getStorage(this.CONSTANTS.USER_INFO)
      this.userInfo = userInfo
      if (userInfo) {
        const { vcorp_name, vname, icorpid, id } = userInfo
        this.purchaseForm.creator_company_name = vcorp_name;
        this.purchaseForm.creator_name = vname;
        this.purchaseForm.creator_company_id = icorpid;
        this.purchaseForm.creator_id = id;
        this.purchaseForm.team_id = this.$route.params.teamId;
        this.purchaseForm.team_name = this.$route.params.teamName;
        this.purchaseForm.project_id = this.$route.params.projectId;
        this.purchaseForm.project_name = this.$route.params.projectName;
        this.purchaseForm.corp_name = this.$route.params.corpName;
        this.getGoods();
      } else {
        this.$router.push({ name: "login" });
      }
    },
    setMembers() {
      this.countPrice();
    },
    setYears(data) {
      if (data) {
        this.purchaseForm.purchase_year = data;
        this.countPrice();
      }
    },
    countPrice() {
      this.purchaseForm.order_amount =
        this.purchaseForm.goods_price *
        this.purchaseForm.goods_quantity *
        this.purchaseForm.purchase_year;
    },
    async billNext() {
      //TODO 默认为一个订单商品
      if (this.userInfo) {
        let orderItem = {};
        orderItem.goods_id = this.purchaseForm.goods_id;
        orderItem.goods_name = this.purchaseForm.goods_name;
        orderItem.goods_quantity = this.purchaseForm.goods_quantity;
        orderItem.purchase_year = this.purchaseForm.purchase_year;
        orderItem.creator_id = this.purchaseForm.creator_id;
        orderItem.creator_company_id = this.purchaseForm.creator_company_id;
        orderItem.creator_company_name = this.purchaseForm.creator_company_name;
        orderItem.creator_name = this.purchaseForm.creator_name;
        orderItem.goods_price = this.purchaseForm.goods_price;
        orderItem.project_name = this.purchaseForm.project_name;
        orderItem.project_id = this.purchaseForm.project_id;
        orderItem.team_id = this.purchaseForm.team_id;
        orderItem.team_name = this.purchaseForm.team_name;
        orderItem.corp_name = this.purchaseForm.crop_name;
        this.purchaseForm.orderItems.push(orderItem);
        this.$fetch("/api/api/buyer/order/save", {
          method: 'post',
          data: this.purchaseForm
        }).then(res => {
          if (res && res.code === '200') {
            this.$Message.success("提交成功");
            this.$router.push({
              name: "confirmOrder",
              query: {
                orderId: res.resData.orderId,
                purchaseForm: JSON.stringify(this.purchaseForm)
              }
            });
          } else {
            this.purchaseForm.orderItems = [];
            this.$Message.error(res.message);
          }
        })
      } else {
        this.$router.push({ name: "login" });
      }
    },
    async getGoods() {
      let list = await this.common.getGoods();
      if (list) {
        this.goodsList = list;
        this.purchaseForm.goods_price = this.goodsList[0].price;
        this.purchaseForm.goods_id = this.goodsList[0].id;
        this.purchaseForm.goods_name = this.goodsList[0].name;
        this.countPrice();
      }
    }
  }
};
</script>

<style lang="less">
.create-order-container {
  margin-top: 30px;
  padding: 30px;
  background: #fff;
  border-radius: 20px;
  min-height: 600px;
  color: #111;

  .main-section {
    margin: 0 auto;
    width: 786px;
  }

  .flex {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 40px;
    color: #111;
  }

  .right-box {
    font-size: 20px;
  }
}

.purchase {
  width: 100%;
  margin: 56px auto;

  .purchase-label {
    width: 140px;
    height: 18px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(67, 67, 67, 1);
    line-height: 27px;
    margin-left: 108px;
  }

  .company-name {
    font-size: 20px;
    line-height: 27px;
  }

  .count-formula {
    margin-top: 17px;
    margin-left: 108px;

    .formula-words {
      font-size: 20px;
      font-weight: 400;
      color: #555;
      line-height: 21px;
    }
  }

  .unit {
    margin-left: 10px;
    font-size: 18px;
    font-weight: 400;
    color: #111;
    line-height: 27px;
  }
}

.purchase-btm {
  margin-bottom: 60px;
  text-align: right;

  label {
    font-size: 30px;
    color: #2dd3ff;
  }

  .count-formula {
    font-size: 20px;
    color: #555555;
  }
}

.btn-area {
  text-align: center;
}

.order-button {
  margin: 0 auto;
  vertical-align: middle;
  width: 140px;
  height: 40px;
  font-size: 16px;
  background: #01c8ff;
  border: 1px solid #01c8ff;
}
</style>
